import { AxiosPromise } from 'axios';
import store from '@/store';
import HttpAdapter from '@/adapters/HttpAdapter';
import { AlarmParamsInterface } from '@/interfaces/AlarmInterface';

export default class ProductApi {
    protected backendURL: string = store.getters['siteConfigModule/getBackend'];

    public getNodeAlarmList(agent_id: number, filters: any): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/agents/${agent_id}/alarms`, { params: { ...filters } });
        // Sorting:
        // - created_at, -name, -measurement, -field, -aggregation, -alarmed_at, -timeframe
        // Filters:
        // - search (string) - searches in name, measurement, field or alert_message
        // - is_disabled (boolean(1|0))
        // - is_alerting (boolean(1|0))
        // - is_alarmed (boolean(1|0))
        // - created_at (time period in format "(int)timestamp|(int)timestamp")
        // - alarmed_at (time period in format "(int)timestamp|(int)timestamp")
        // - aggregation (one of possible values, see aggregation list request)
        // - threshold_criteria (one of possible values, see threshold criteria list request)
        // - threshold_value (integer)
        // - alert_severity (one of possible values, see /web-api/misc/log-levels request)
        // - tags (one or many of possible values from tags attribute from this alarms list response)
    }

    public getAlarmAggregationList(): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/alarms/aggregation-list`);
    }

    public getAlarmThresholdCriteriaList(): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/alarms/threshold-criteria-list`);
    }

    public getAddAlarmNodeInfoAboutMetrics(agent_id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/agents/${agent_id}/alarms/add`);
        // Get info about metrics (needed for add alarm POST request)
    }

    public nodeAddAlarm(agent_id: number, params: AlarmParamsInterface): AxiosPromise {
        return HttpAdapter.post(`${this.backendURL}/web-api/agents/${agent_id}/alarms/add`, { ...params });
    }

    public getEditAlarmNodeInfoAboutMetrics(alarm_id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/alarms/${alarm_id}/edit`);
        // Get info about metrics and alarm details (needed for edit alarm POST request)
    }

    public nodeEditAlarm(alarm_id: number, params: AlarmParamsInterface): AxiosPromise {
        return HttpAdapter.post(`${this.backendURL}/web-api/alarms/${alarm_id}/edit`, { ...params });
    }

    public getAlarmDetails(alarm_id: number): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/alarms/${alarm_id}`);
    }

    public deleteAlarms(ids: number[]): AxiosPromise {
        return HttpAdapter.post(`${this.backendURL}/web-api/alarms/delete`, { ids });
    }

    public disableAlarms(ids: number[]): AxiosPromise {
        return HttpAdapter.post(`${this.backendURL}/web-api/alarms/disable`, { ids });
    }

    public enableAlarms(ids: number[]): AxiosPromise {
        return HttpAdapter.post(`${this.backendURL}/web-api/alarms/enable`, { ids });
    }

    public getAlarmDashboardQuery(agent_id: number, filters: any): AxiosPromise {
        return HttpAdapter.get(`${this.backendURL}/web-api/agents/${agent_id}/alarms/dashboard-query`, {
            params: { ...filters }
        });
        // taken from agent metrics:
        // - measurement
        // - tags
        // other needed:
        // - aggregation
        // - threshold_value
        // chart specific:
        // - step
        // In case of inconsistency or absence of params there will be a response with 200 and empty result
    }
}
