import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueRouter, { RouteConfig } from 'vue-router';
import {
    isLoggedIn,
    guard,
    logoutGuard,
    resetPasswordGuard,
} from '@/utils/GuardUtil';

Vue.use(VueRouter);
Vue.use(VueMeta);

export const DEFAULT_START_PAGE = {
    path: '/explore',
    name: 'explore',
    alternativePath: '/solutions'
};

const routes: Array<RouteConfig> = [
    {
        path: '*',
        name: 'not-found',
        component: () => import('@/views/NotFound.vue'),
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        redirect: DEFAULT_START_PAGE.path
    },
    {
        path: '/my-dashboard',
        name: 'my-dashboard',
        redirect: DEFAULT_START_PAGE.path
    },
    {
        path: '/appliances',
        name: 'appliances',
        redirect: '/nodes'
    },
    {
        path: '/appliance/:id',
        name: 'appliance',
        redirect: '/node/:id/detail'
    },
    {
        path: '/',
        name: 'entry',
        redirect: DEFAULT_START_PAGE.path
    },
    {
        path: '/home',
        name: 'home',
        redirect: DEFAULT_START_PAGE.path
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        beforeEnter: isLoggedIn,
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/ForgotPassword.vue'),
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        beforeEnter: resetPasswordGuard,
        component: () => import('@/views/ResetPassword.vue'),
    },
    {
        path: '/logout',
        name: 'logout',
        beforeEnter: logoutGuard,
    },
    {
        path: '/organizations',
        name: 'customers',
        component: () => import('@/views/Customers.vue'),
        beforeEnter: guard,
    },
    {
        path: '/organization/:id/alerts',
        name: 'customer-alerts',
        component: () => import('@/views/Customer.vue'),
        beforeEnter: guard,
    },
    {
        path: '/organization/:id/nodes',
        name: 'customer-nodes',
        component: () => import('@/views/Customer.vue'),
        beforeEnter: guard,
    },
    {
        path: '/organization/:id/node-activity',
        name: 'customer-node-activity',
        component: () => import('@/views/Customer.vue'),
        beforeEnter: guard,
    },
    {
        path: '/organization/:id/partner',
        name: 'customer-partners',
        component: () => import('@/views/Customer.vue'),
        beforeEnter: guard,
    },
    {
        path: '/organization/:id/users',
        name: 'customer-users',
        component: () => import('@/views/Customer.vue'),
        beforeEnter: guard,
    },
    {
        path: '/organization/:id/detail',
        name: 'customer-details',
        component: () => import('@/views/Customer.vue'),
        beforeEnter: guard,
    },
    {
        path: '/organization/:id/media-read-written',
        name: 'customer-media-rw',
        component: () => import('@/views/Customer.vue'),
        beforeEnter: guard,
    },
    {
        path: '/organization/:id/media-usage',
        name: 'customer-media-usage',
        component: () => import('@/views/Customer.vue'),
        beforeEnter: guard,
    },
    {
        path: '/organization/:id/logs',
        name: 'customer-logs',
        component: () => import('@/views/Customer.vue'),
        beforeEnter: guard,
    },
    {
        path: '/organization/:id/performance',
        name: 'customer-performance',
        component: () => import('@/views/Customer.vue'),
        beforeEnter: guard,
    },
    {
        path: '/performance',
        name: 'performance',
        component: () => import('@/views/Performance.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solutions',
        name: 'solutions',
        component: () => import('@/views/Solutions.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solution/:id/detail',
        name: 'solution-details',
        component: () => import('@/views/Solution.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solution/:id/media-read-written',
        name: 'solution-media-rw',
        component: () => import('@/views/Solution.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solution/:id/media-usage',
        name: 'solution-media-usage',
        component: () => import('@/views/Solution.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solution/:id/dashboard-fseries',
        name: 'solution-dashboard-fseries',
        component: () => import('@/views/Solution.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solution/:id/dashboard-hseries',
        name: 'solution-dashboard-hseries',
        component: () => import('@/views/Solution.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solution/:id/dashboard-dxi',
        name: 'solution-dashboard-dxi',
        component: () => import('@/views/Solution.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solution/:id/dashboard-nvr',
        name: 'solution-dashboard-nvr',
        component: () => import('@/views/Solution.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solution/:id/dashboard-stornext',
        name: 'solution-dashboard-stornext',
        component: () => import('@/views/Solution.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solution/:id/cluster-configuration',
        name: 'solution-cluster-configuration',
        component: () => import('@/views/Solution.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solution/:id/alerts',
        name: 'solution-alerts',
        component: () => import('@/views/Solution.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solution/:id/nodes',
        name: 'solution-nodes',
        component: () => import('@/views/Solution.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solution/:id/node-activity',
        name: 'solution-node-activity',
        component: () => import('@/views/Solution.vue'),
        beforeEnter: guard,
    },
    {
        path: '/solution/:id/logs',
        name: 'solution-logs',
        component: () => import('@/views/Solution.vue'),
        beforeEnter: guard,
    },
    {
        path: '/nodes',
        name: 'nodes',
        props: true,
        component: () => import('@/views/Nodes.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node-activity',
        name: 'node-activity',
        props: true,
        component: () => import('@/views/Nodes.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/detail',
        name: 'node-details',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/license-report',
        name: 'node-license-report',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/alerts',
        name: 'node-alerts',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/alarms',
        name: 'node-alarms',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/logs',
        name: 'node-logs',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-dashboard',
        name: 'performance-dashboard',
        redirect: '/node/:id/detail'
    },
    {
        path: '/node/:id/performance-capacity',
        name: 'performance-capacity',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-system-interfaces',
        name: 'performance-system-interfaces',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-fibre-channel',
        name: 'performance-fibre-channel',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-fibre-channel-error-types',
        name: 'performance-fibre-channel-error-types',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-cpu',
        name: 'performance-cpu',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-system-resources',
        name: 'performance-system-resources',
        redirect: '/node/:id/performance-cpu'
    },
    {
        path: '/node/:id/performance-memory',
        name: 'performance-memory',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-file-systems',
        name: 'performance-file-systems',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-storage-tiers',
        name: 'performance-storage-tiers',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-vm',
        name: 'performance-vm',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-storage-cpu',
        name: 'performance-storage-cpu',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-storage-memory',
        name: 'performance-storage-memory',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-storage-disk',
        name: 'performance-storage-disk',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-storage-interfaces',
        name: 'performance-storage-interfaces',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/performance-vms',
        name: 'performance-vms',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/media',
        name: 'node-media',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/media-read-written',
        name: 'node-media-rw',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/high-availability',
        name: 'node-high-availability',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/cluster-configuration',
        name: 'node-cluster-configuration',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/smb',
        name: 'smb',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-disks',
        name: 'configuration-disks',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/storage',
        name: 'node-storage-storage',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration',
        name: 'node-configuration',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/environmentals',
        name: 'node-environmentals',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-management-network',
        name: 'configuration-management-network',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-stornext-system',
        name: 'configuration-stornext-system',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-storage-networks',
        name: 'configuration-storage-networks',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-interfaces',
        name: 'configuration-interfaces',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-bmc',
        name: 'configuration-bmc',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-email',
        name: 'configuration-email',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-time',
        name: 'configuration-time',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-snmp',
        name: 'configuration-snmp',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-sed',
        name: 'configuration-sed',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-file-systems',
        name: 'configuration-file-systems',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-appliance-information',
        name: 'configuration-appliance-information',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-stripe-groups',
        name: 'configuration-stripe-groups',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-about',
        name: 'configuration-about',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-fsm-services',
        name: 'configuration-fsm-services',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-hosts',
        name: 'configuration-hosts',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-fsmpm-services',
        name: 'configuration-fsmpm-services',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-disk-type-summary',
        name: 'configuration-disk-type-summary',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-clients',
        name: 'configuration-clients',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-group-quotas',
        name: 'configuration-group-quotas',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/configuration-user-quotas',
        name: 'configuration-user-quotas',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/health-system',
        name: 'health-system',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/health-smart',
        name: 'health-smart',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/health-storage',
        name: 'health-storage',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/history-cba-availability',
        name: 'history-cba-availability',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/history-reports',
        name: 'history-reports',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/history-reports/:reportId/details',
        name: 'history-report-details',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/history-file-uploads',
        name: 'history-file-uploads',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/history-file-uploads/detail/:fileUploadsId',
        name: 'history-file-uploads-detail',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/history-remote-jobs',
        name: 'history-remote-jobs',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/node/:id/history-remote-jobs/:jobId/detail',
        name: 'history-remote-jobs-detail',
        component: () => import('@/views/Node.vue'),
        beforeEnter: guard,
    },
    {
        path: '/alerts',
        name: 'alerts',
        component: () => import('@/views/Alerts.vue'),
        beforeEnter: guard,
    },
    {
        path: '/alert/:id/detail',
        name: 'alert-details',
        component: () => import('@/views/Alert.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore',
        name: 'explore',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-performance',
        name: 'explore-performance',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-capacity',
        name: 'explore-capacity',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-service-contracts',
        name: 'explore-service-contracts',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-solutions',
        name: 'explore-solutions',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-organizations',
        name: 'explore-organizations',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-nodes',
        name: 'explore-nodes',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-users',
        name: 'explore-users',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-release-trees',
        name: 'explore-release-trees',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-release-trees/:id/release-version',
        name: 'explore-release-versions',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-product-versions',
        name: 'explore-product-versions',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-cba-adoption',
        name: 'explore-cba-adoption',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-cba-availability',
        name: 'explore-cba-availability',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-remote-jobs',
        name: 'explore-remote-jobs',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-remote-jobs/:jobId/job-details',
        name: 'explore-remote-job-details',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/reverse-tunnel-activity',
        name: 'reverse-tunnel-activity',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/reverse-tunnel-activity/:jobId/job-details',
        name: 'reverse-tunnel-activity-job-details',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-file-uploads',
        name: 'explore-file-uploads',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-file-uploads/:id/detail',
        name: 'explore-file-uploads-details',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-stornext-file-systems',
        name: 'explore-stornext-file-systems',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-stornext-subscriptions',
        name: 'explore-stornext-subscriptions',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-alarms',
        name: 'explore-alarms',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-partner-assignments',
        name: 'explore-partner-assignments',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-reports',
        name: 'explore-reports',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/explore-reports/:nodeId/details/:reportId',
        name: 'explore-report-details',
        component: () => import('@/views/Explore.vue'),
        beforeEnter: guard,
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/Users.vue'),
        beforeEnter: guard,
    },
    {
        path: '/user/:id/detail',
        name: 'user-details',
        component: () => import('@/views/User.vue'),
        beforeEnter: guard,
    },
    {
        path: '/user/:id/logs',
        name: 'user-logs',
        component: () => import('@/views/User.vue'),
        beforeEnter: guard,
    },
    {
        path: '/user/:id/notifications',
        name: 'user-notifications',
        component: () => import('@/views/User.vue'),
        beforeEnter: guard,
    },
    {
        path: '/user/:id/activity',
        name: 'user-activity',
        component: () => import('@/views/User.vue'),
        beforeEnter: guard,
    },
    {
        path: '/feedback',
        name: 'feedback',
        props: true,
        component: () => import('@/components/support/Feedback.vue'),
        beforeEnter: guard,
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/components/about/About.vue'),
        beforeEnter: guard,
    },
    {
        path: '/report-missing-node',
        name: 'ReportMissingNode',
        props: true,
        component: () => import('@/components/support/ReportMissingNode.vue'),
        beforeEnter: guard,
    },
    {
        path: '/messages',
        name: 'messages',
        props: true,
        component: () => import('@/views/Messages.vue'),
        beforeEnter: guard,
    },
    {
        path: '/mystornext',
        name: 'mystornext',
        component: () => import('@/views/MyStorNext.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
